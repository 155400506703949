import type { ClientSpecMapAPI, IModelsAPI, PlatformEnvData } from '@wix/thunderbolt-symbols'

// TODO: Share this constant
const BlocksPreviewAppDefId = '46b2ad43-5720-41d2-8436-2058979cb53f'

export const isTelemetryWixCodeSdkRequired = ({
	modelsApi,
	clientSpecMapApi,
	platformEnvData,
}: {
	modelsApi: IModelsAPI
	clientSpecMapApi: ClientSpecMapAPI
	platformEnvData: PlatformEnvData
}) => {
	const applications = modelsApi.getApplications()

	const isAppRunning = (appDefId?: string) => Boolean(appDefId && applications[appDefId])

	const isWixCodeRunning = isAppRunning(clientSpecMapApi.getWixCodeAppDefinitionId())
	const isDataBindingRunning = isAppRunning(clientSpecMapApi.getDataBindingAppDefinitionId())
	const isBlocksEditorRunning = isAppRunning(BlocksPreviewAppDefId)
	const isBlocksRunning = clientSpecMapApi.getBlocksAppsAppDefinitionIds().some(isAppRunning)

	const isExperimentEnabled = Boolean(platformEnvData.site.experiments['specs.thunderbolt.WixCodeReplaceConsole'])

	return isExperimentEnabled && (isWixCodeRunning || isDataBindingRunning || isBlocksEditorRunning || isBlocksRunning)
}
